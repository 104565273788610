<template>
  <v-container fluid>
    <crm-table-pageable-new
        :headers="headers"
        :data="data"
        :config="config"
        :loaded-options="options"
        :total-elements="totalElements"
        :total-pages="totalPages"
        addButtonTitle="+ Договор"
        @onChangeData="onChangeData"
        @handleActions="handle"
    ></crm-table-pageable-new>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';

export default {
  name: 'index',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'id', sortable: false, text: '#', type: 'defaultItem',
        },
        {
          value: 'name', sortable: false, text: 'Наименование', type: 'defaultItem',
        },
        {
          value: 'createdDate', sortable: true, text: 'Дата создания', type: 'datetime',
        },
        {
          value: 'bin', sortable: false, text: 'ИИН/БИН', type: 'defaultItem',
        },
        {
          value: 'telephone', sortable: false, text: 'Телефон', type: 'defaultItem',
        },
        {
          value: 'email', sortable: false, text: 'Email', type: 'defaultItem',
        },
        {
          value: 'actions',
          text: 'Действие',
          type: 'actions',
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              visible: true,
            },
            {
              label: 'Удалить',
              event: 'delete',
              icon: 'mdi-delete',
              visible: true,
            },
          ],
        },
      ],
      data: [],
      config: {
        add: false,
        search: false,
        pageable: true,
      },
      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/crm/supplier/contracts',
      options: {
        page: 1,
        size: 10,
        sort: 'createdDate,desc',
      },

    };
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getSuppliers();
    },
    getSuppliers() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$api.get(formattedUrl, {
        params: {
          page: params.page - 1,
          size: params.size,
          sort: params.sort,
        },
      }).then((response) => {
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
        this.data = response.content;
      }).finally(() => {
        this.$loading(false);
      });
    },
    handle() {
    },
  },
};
</script>

<style scoped>

</style>
